import { Select } from 'antd';
import React from 'react';
var Option = Select.Option;
export var RulesSelect = function (props) {
    var multiple = props.multiple, _a = props.options, options = _a === void 0 ? [] : _a, _b = props.value, value = _b === void 0 ? multiple ? [] : '' : _b, onChange = props.onChange, _c = props.type, type = _c === void 0 ? '' : _c, _d = props.keyWord, keyWord = _d === void 0 ? 'name' : _d, disabled = props.disabled, allowClear = props.allowClear, _e = props.showSearch, showSearch = _e === void 0 ? false : _e, _f = props.optionFilterProp, optionFilterProp = _f === void 0 ? 'children' : _f;
    return (React.createElement(Select, { showSearch: showSearch, value: value, onChange: function (value) {
            onChange(value, type);
        }, allowClear: allowClear, disabled: disabled, mode: multiple ? 'multiple' : undefined, optionFilterProp: optionFilterProp, style: { width: 160, margin: '0 10px 10px 0' } }, options === null || options === void 0 ? void 0 : options.map(function (i) {
        return (React.createElement(Option, { key: i.name, value: i[keyWord] }, i.label));
    })));
};
