import { __assign, __read, __spread } from "tslib";
/**
 *  Created by gym on 2019-07-31 16:30.
 */
import { app as api } from '@ekuaibao/whispered';
import { get, groupBy, cloneDeep, unionBy } from 'lodash';
var textFields = [
    { label: i18n.get('包含'), value: 'contains' },
    { label: i18n.get('不包含'), value: 'not contains' },
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' }
];
var staffTags = [
    {
        value: 'in',
        label: i18n.get('是')
    },
    {
        value: 'notIn',
        label: i18n.get('不是')
    }
];
var dimensionFields = [
    { label: i18n.get('属于'), value: 'in' },
    { label: i18n.get('不属于'), value: 'not in' }
];
var numberFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('大于'), value: '>' },
    { label: i18n.get('小于'), value: '<' },
    { label: i18n.get('大于等于'), value: '>=' },
    { label: i18n.get('小于等于'), value: '<=' }
];
var dateFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('早于'), value: '<' },
    { label: i18n.get('不早于'), value: '>=' },
    { label: i18n.get('晚于'), value: '>' },
    { label: i18n.get('不晚于'), value: '<=' },
    { label: i18n.get('属于'), value: 'in' },
    { label: i18n.get('不属于'), value: 'not in' }
];
export var dateRange = [
    { label: i18n.get('本周'), value: 'THIS_WEEK' },
    { label: i18n.get('上周'), value: 'LAST_WEEK' },
    { label: i18n.get('本月'), value: 'THIS_MONTH' },
    { label: i18n.get('上月'), value: 'LAST_MONTH' },
    { label: i18n.get('本年'), value: 'THIS_YEAR' },
    { label: i18n.get('去年'), value: 'LAST_YEAR' }
];
var switcherFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' }
];
var feeTypeFields = [
    { label: i18n.get('是'), value: '=' },
    { label: i18n.get('不是'), value: '!=' }
];
var baseChildren = [
    { label: i18n.get('名称'), value: 'name' },
    { label: i18n.get('编码'), value: 'code' }
];
var moneyChildren = [{ label: i18n.get('本位币'), value: 'standard' }];
var plannedChildren = [
    { label: i18n.get('百分比'), value: 'percentage' },
    { label: i18n.get('余额'), value: 'balance' }
];
var staffChildren = [
    { label: i18n.get('姓名'), value: 'name' },
    { label: i18n.get('工号'), value: 'code' },
    { label: i18n.get('邮箱'), value: 'email' },
    { label: i18n.get('手机号'), value: 'cellphone' }
];
var staffOnlyNameChild = [{ label: i18n.get('姓名'), value: 'name' }];
var payeeInfoChildren = [
    { label: i18n.get('开户行'), value: 'bank' },
    { label: i18n.get('开户网点'), value: 'branch' },
    { label: i18n.get('户名'), value: 'name' },
    { label: i18n.get('账号'), value: 'accountNo' }
];
export var expenseSelectTextOptions = [{ label: i18n.get('关联的单号'), value: 'flowCode' }];
export var expenseSelectTimeOptions = [
    { label: i18n.get('消费开始时间'), value: 'startTime' },
    { label: i18n.get('消费结束时间'), value: 'endTime' }
];
export var showStaffName = [{ label: i18n.get('姓名'), value: 'STAFF_NAME' }];
export var showStaffDeptName = [{ label: i18n.get('默认部门名称'), value: 'DEP_NAME' }];
export var showStaffDeptCode = [{ label: i18n.get('默认部门编码'), value: 'DEP_CODE' }];
export var showStaffActiveOptions = [
    { label: i18n.get('启用中'), value: '是' },
    { label: i18n.get('已停用'), value: '否' }
];
export function getBaseSource(params) {
    var entityName = params.entityName, sourceTypeFieldName = params.sourceTypeFieldName, useOriginalData = params.useOriginalData, _a = params.othersSource, othersSource = _a === void 0 ? [] : _a, isNewOrder = params.isNewOrder;
    if (useOriginalData) {
        return __spread([{ label: i18n.get('给定值'), value: 'CONSTANT' }], othersSource);
    }
    var result = [{ label: i18n.get('给定值'), value: 'CONSTANT' }];
    if (!!sourceTypeFieldName) {
        result.push({ label: sourceTypeFieldName, value: 'BILL_FIELD' });
    }
    if (!isNewOrder) {
        result.push({ label: i18n.get("{__k0}\u5B57\u6BB5", { __k0: entityName }), value: 'DATALINK_FIELD' });
    }
    return result;
}
export function getFilterSource() {
    return [
        { label: i18n.get('给定值'), value: 'CONSTANT' },
        { label: i18n.get('可查看人员的'), value: 'STAFF' }
    ];
}
export function getStaffSource() {
    return [{ label: i18n.get('可查看人员的'), value: 'STAFF_RECORD' }];
}
export var CONSTANT = [{ label: i18n.get('给定值'), value: 'CONSTANT' }];
export function getDateSource(params) {
    var entityName = params.entityName, sourceTypeFieldName = params.sourceTypeFieldName, isNewOrder = params.isNewOrder;
    if (isNewOrder) {
        return [
            { label: i18n.get('给定值'), value: 'CONSTANT' },
            { label: sourceTypeFieldName, value: 'BILL_FIELD' },
            { label: i18n.get('动态范围'), value: 'DYNAMIC' },
            { label: i18n.get('相对今天'), value: 'RELATIVE_TODAY' }
        ];
    }
    return [
        { label: i18n.get('给定值'), value: 'CONSTANT' },
        { label: sourceTypeFieldName, value: 'BILL_FIELD' },
        { label: i18n.get("{__k0}\u5B57\u6BB5", { __k0: entityName }), value: 'DATALINK_FIELD' },
        { label: i18n.get('动态范围'), value: 'DYNAMIC' },
        { label: i18n.get('相对今天'), value: 'RELATIVE_TODAY' }
    ];
}
//自定义扩展-业务对象-筛选条件 '可查看人员的'
export function getFieldsTypeForDataFilter(subjectData, key) {
    var _a;
    if (!key || !subjectData.length) {
        return false;
    }
    var keyStr = key.split('.')[0];
    var field = subjectData.find(function (item) { return item.name === keyStr; });
    var types = ['organization.Staff', 'organization.Department'];
    if (!!~types.indexOf((field === null || field === void 0 ? void 0 : field.entity) || ((_a = field === null || field === void 0 ? void 0 : field.elemType) === null || _a === void 0 ? void 0 : _a.entity))) {
        return true;
    }
    return false;
}
export function getFieldsType(subjectData, key) {
    if (!key || !subjectData.length) {
        return '';
    }
    var keyStr = key.split('.')[0];
    var field = subjectData.find(function (item) { return item.name === keyStr; });
    if (key.split('.').length > 2)
        return isDataLnikField(field) ? 'text' : 'number';
    if (isDataLnikField(field)) {
        // 如果是业务对象，则使用二级联动的type
        var dataKey_1 = key.split('.')[1];
        var subjectDataList = field.children || [];
        var fieldItem = subjectDataList.find(function (item) { return item.name === dataKey_1; });
        return getType(fieldItem);
    }
    else if (field) {
        switch (getType(field)) {
            case 'ref':
                var entity = getDataEntity(field);
                if (entity.startsWith('basedata.Dimension.')) {
                    var dataKey = key.split('.')[1];
                    if (dataKey === 'name' || dataKey === 'code') {
                        return 'text';
                    }
                    else {
                        return 'Dimension';
                    }
                }
                return entity.startsWith('organization.Department') ||
                    entity.startsWith('organization.Staff') ||
                    entity.startsWith('basedata.Enum')
                    ? 'text'
                    : getType(field);
            case 'money':
                return 'number';
            case 'switcher':
                return 'boolean';
            default:
                return getType(field);
        }
    }
}
export var operatorDataMap = {
    text: textFields,
    autoNumber: textFields,
    number: numberFields,
    date: dateFields,
    boolean: switcherFields,
    feeTypeId: feeTypeFields,
    staff: staffTags,
    Dimension: dimensionFields
};
export function formatEntityList(data, isNewOrder) {
    if (!data)
        return [];
    var _a = data.fields, fields = _a === void 0 ? [] : _a, _b = data.planned, planned = _b === void 0 ? [] : _b, _c = data.isRefChild, isRefChild = _c === void 0 ? true : _c, isNeedAllFileds = data.isNeedAllFileds, _d = data.showDimension, showDimension = _d === void 0 ? false : _d;
    var fieldList = isNeedAllFileds
        ? fields
        : fields.filter(function (oo) { return getDataEntity(oo) !== 'organization.Staff' && getDataEntity(oo) !== 'basedata.city'; });
    if (planned && planned.length) {
        var planList = planned.map(function (line) {
            return formatPlanned(line);
        });
        fieldList = fieldList.concat(planList);
    }
    return fieldList.map(function (item) {
        return formatFieldType({ item: item, isRefChild: isRefChild, isStaffChild: false, showDimension: showDimension }, isNewOrder);
    });
}
function formatPlanned(line) {
    var plannedField = __assign(__assign({}, line), { value: line.id, name: line.id, label: line.name, dataType: { type: 'number' } });
    plannedField.children = plannedChildren;
    return plannedField;
}
export function formatFieldType(params, isNewOrder) {
    var item = params.item, isRefChild = params.isRefChild, isStaffChild = params.isStaffChild, showDimension = params.showDimension;
    var result = __assign(__assign({}, item), { value: item.name, label: item.label });
    var entity = getDataEntity(item);
    if (isRefChild &&
        getType(item) &&
        ((entity === null || entity === void 0 ? void 0 : entity.startsWith('basedata.Dimension.')) || (entity === null || entity === void 0 ? void 0 : entity.startsWith('organization.Department')) || (entity === null || entity === void 0 ? void 0 : entity.startsWith('basedata.Enum')))) {
        result.children = baseChildren;
        if (showDimension &&
            entity.startsWith('basedata.Dimension.') &&
            !result.children.find(function (o) { return o.label === item.label; })) {
            result.children = __spread([{ label: item.label, value: item.name }], baseChildren);
        }
    }
    if (isRefChild &&
        isStaffChild &&
        getType(item) &&
        (entity.startsWith('organization.Staff.') || entity.startsWith('organization.Staff'))) {
        result.children = staffChildren;
    }
    if (item.isStaffOnlyNameChild) {
        result.children = staffOnlyNameChild;
    }
    if (getType(item) === 'money' && !isNewOrder) {
        result.children = moneyChildren;
    }
    return result;
}
export function getAllBillsField(value) {
    var list;
    var specificationGroups = cloneDeep(value);
    var dataLinkInfoAll = api.getState()['@custom-specification'].entityList; //api.getState()['@bills'].dataLink['all']//
    var globalfields = api.getState()['@common'].globalFields.data;
    var specifications = [];
    specificationGroups === null || specificationGroups === void 0 ? void 0 : specificationGroups.map(function (v) {
        specifications = specifications.concat(v.specifications);
    });
    var templateFieldList = [];
    specifications.map(function (item) {
        item.value = item.id;
        item.label = item.name;
        var field = getTempFieldByAbility("billSpecification:" + item.type, item.type).field;
        var templateField = [];
        item.components.forEach(function (oo) {
            var comfield = globalfields.find(function (vv) { return vv.name === oo.field; });
            comfield && templateField.push(comfield);
        });
        templateField = unionBy(templateField, field, 'name');
        templateFieldList = unionBy(templateFieldList, templateField, 'name');
    });
    templateFieldList.forEach(function (item) {
        if (getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity')) {
            var entityId_1 = getDataEntity(item).split('.');
            var findEntity = dataLinkInfoAll.find(function (i) { return i.id === entityId_1[entityId_1.length - 1]; });
            item.children = formatEntityList(cloneDeep(findEntity));
        }
    });
    list = formatTempFieldValue(templateFieldList, false);
    return list;
}
export function getAllFeeTypeField(value) {
    var list;
    var dataLinkInfoAll = api.getState()['@custom-specification'].entityList;
    var feeTypelist = treeToArr(cloneDeep(value));
    var globalfields = api.getState()['@common'].globalFields.data;
    var templateFieldList = [];
    feeTypelist === null || feeTypelist === void 0 ? void 0 : feeTypelist.map(function (item) {
        var _a, _b;
        item.value = item.id;
        item.label = item.name;
        var expenseField = [], requisitionField = [];
        //全部费用类型 报销字段
        var templateField = getTempFieldByAbility('feetypeSpecification', '');
        (_a = item.feetypeTemplate) === null || _a === void 0 ? void 0 : _a.expenseComponents.forEach(function (oo) {
            var comfield = globalfields.find(function (vv) { return vv.name === oo.field; });
            comfield && expenseField.push(comfield);
        });
        (_b = item.feetypeTemplate) === null || _b === void 0 ? void 0 : _b.requisitionComponents.forEach(function (oo) {
            var comfield = globalfields.find(function (vv) { return vv.name === oo.field; });
            comfield && requisitionField.push(comfield);
        });
        expenseField = unionBy(expenseField, (templateField === null || templateField === void 0 ? void 0 : templateField.field) || [], 'name');
        expenseField = unionBy(expenseField, requisitionField, 'name');
        templateFieldList = unionBy(templateFieldList, expenseField, 'name');
    });
    templateFieldList.forEach(function (item) {
        if (getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity')) {
            var entityId_2 = getDataEntity(item).split('.');
            var findEntity = dataLinkInfoAll.find(function (i) { return i.id === entityId_2[entityId_2.length - 1]; });
            item.children = formatEntityList(cloneDeep(findEntity));
        }
    });
    list = formatTempFieldValue(templateFieldList, false);
    return list;
}
//从 web 项目 DataLinkComplexFilter 扒过来的
export function getTempFieldByAbility(settingType, billType) {
    var globalfields = api.getState()['@common'].globalFields.data;
    if (settingType.startsWith('billSpecification')) {
        var billTypeMeta = getBillType(billType);
        var configs = (billTypeMeta && billTypeMeta.configs) || [];
        var abilityList_1 = configs.map(function (o) { return o.ability; });
        var field = globalfields
            .filter(function (v) { return v.ability && !!~abilityList_1.indexOf(v.ability); })
            .filter(function (oo) { return get(oo, 'dataType.entity') !== 'requisition.RequisitionInfo'; });
        return { field: field, sourceType: i18n.get('单据字段') };
    }
    else if (settingType.startsWith('feetypeSpecification')) {
        var field = globalfields
            .filter(function (v) { return v.ability && v.ability === 'feeDetail'; })
            .filter(function (oo) { return oo.name !== 'detailId' && oo.name !== 'multiplePayeesMode'; });
        return { field: field, sourceType: i18n.get('费用明细字段') };
    }
    else {
        var fields = globalfields.filter(function (v) { return v.ability && v.ability === 'trip'; }).filter(function (oo) { return oo.name !== 'tripId'; });
        var field = fields.filter(function (vv) { return get(vv, 'dataType.entity') !== 'basedata.city'; });
        return { field: field, sourceType: i18n.get('行程明细字段') };
    }
}
//自定义分摊时字段选择业务对象联查，取分摊明细的___字段
export function filterApportionField() {
    var extensionFields = api.getState()['@common'].globalFields.extension;
    var fields = [];
    extensionFields.forEach(function (v) {
        var dataType = v.dataType, name = v.name;
        if (((v.hasOwnProperty('ability') && v.ability === '') || !v.hasOwnProperty('ability')) &&
            dataType.type === 'ref' &&
            dataType.entity) {
            if (!!~dataType.entity.indexOf('organization.Staff'))
                fields.push(v);
            if (!!~dataType.entity.indexOf('organization.Department'))
                fields.push(v);
            if (!!~dataType.entity.indexOf('basedata.Dimension'))
                fields.push(v);
            if (!!~dataType.entity.indexOf('datalink.DataLinkEntity') && name.startsWith('u_'))
                fields.push(v);
        }
    });
    var list = formatTempFieldValue(fields, false);
    return list;
}
export function getBillType(billType) {
    switch (billType) {
        case 'expense':
            return api.getState()['@custom-specification'].expenseMeta;
        case 'requisition':
            return api.getState()['@custom-specification'].requisitionMeta;
        case 'loan':
            return api.getState()['@custom-specification'].loanMeta;
        case 'custom':
            return api.getState()['@custom-specification'].customMeta;
        default:
            return api.getState()['@custom-specification'].paymentMeta;
    }
}
function treeToArr(tree) {
    var arrs = [], result = [];
    arrs = arrs.concat(tree);
    while (arrs.length) {
        var first = arrs.shift();
        if (first.children) {
            arrs = arrs.concat(first.children);
            delete first['children'];
        }
        result.push(first);
    }
    return result;
}
//targetField: 需要赋值的字段 ；entityList ：业务对象的字段
export function getDataLinkFieldByType(entityField, targetField) {
    // 根据模版的字段类型过滤
    if (!entityField)
        return entityField;
    entityField.map(function (i) {
        !i.value && (i.value = i.name);
    });
    var globalfields = api.getState()['@common'].globalFields.data;
    var line = globalfields.find(function (vv) { return vv.name === targetField.field; });
    var targetType = getType(line);
    var result = cloneDeep(entityField);
    if (getType(line) === 'ref') {
        var entity_1 = getDataEntity(line);
        result = result === null || result === void 0 ? void 0 : result.filter(function (v) {
            return getDataEntity(v) === entity_1;
        });
    }
    else {
        result = result === null || result === void 0 ? void 0 : result.filter(function (v) {
            return getType(v) === targetType || v.type === targetType;
        });
    }
    return result;
}
//withGroup : 取值不按字段类型区分
export function formatTempFieldValue(data, withGroup) {
    if (withGroup === void 0) { withGroup = true; }
    if (!data || !data.length)
        return [];
    var fields = data
        .filter(function (item) { return item.type !== 'separator'; })
        .map(function (line) {
        var result = __assign(__assign({}, line), { value: line.field || line.name });
        if (getType(line) === 'ref' && !getDataEntity(line).startsWith('datalink.DataLinkEntity')) {
            result.children = getResultChildren(getDataEntity(line));
        }
        if (getType(line) === 'money') {
            result.children = moneyChildren;
        }
        return result;
    });
    if (!withGroup) {
        return fields;
    }
    var dataLinkList = fields.filter(function (line) { return isDataLnikField(line); });
    var arr = [];
    getNewTemFieldValue(dataLinkList, arr);
    var fieldList = fields.filter(function (line) { return !isDataLnikField(line); }).concat(arr);
    return templateGroup(fieldList);
}
function getNewTemFieldValue(dataLinkList, arr) {
    dataLinkList.forEach(function (item) {
        var childrenGroup = groupBy(item.children, function (oo) {
            //money类型.本位币当做数字类型处理
            if (oo.children && getType(oo) === 'money') {
                return 'number';
            }
            else if (oo.children && getDataEntity(oo).startsWith('basedata.Dimension')) {
                return 'text';
            }
            else {
                return getType(oo);
            }
        });
        Object.keys(childrenGroup).forEach(function (vv) {
            var str = __assign({}, item);
            str['copyType'] = vv;
            str.children = childrenGroup[vv];
            arr.push(str);
        });
    });
}
function templateGroup(fieldList) {
    return groupBy(fieldList, function (line) {
        if (line.children) {
            if (getType(line) === 'money') {
                return 'number';
            }
            else if (line.copyType) {
                return line.copyType;
            }
            else {
                return 'text';
            }
        }
        else {
            return getType(line);
        }
    });
}
export function getResultChildren(entity) {
    switch (entity) {
        case 'organization.Department':
            return baseChildren;
        case 'organization.Staff':
            return staffChildren;
        case 'pay.PayeeInfo':
            return payeeInfoChildren;
        default:
            return baseChildren;
    }
}
export function getEntityListForDataFilter(arr, type, left, dimensionList) {
    var _a, _b, _c;
    if (!left || !arr.length) {
        return [];
    }
    var keyStr = left.split('.')[0];
    var field = arr.find(function (item) { return item.name === keyStr; });
    //人员 -只有姓名
    if (!!~['organization.Staff'].indexOf((field === null || field === void 0 ? void 0 : field.entity) || ((_a = field === null || field === void 0 ? void 0 : field.elemType) === null || _a === void 0 ? void 0 : _a.entity))) {
        return showStaffName;
    }
    //自定义档案
    if ((_b = field === null || field === void 0 ? void 0 : field.entity) === null || _b === void 0 ? void 0 : _b.startsWith('basedata.Dimension')) {
        var list = dimensionList[field === null || field === void 0 ? void 0 : field.entity] || [];
        var newList = list.map(function (item) {
            return { label: item.name, value: item.id };
        });
        return newList;
    }
    //部门-默认部门名称、默认部门编码
    if (!!~['organization.Department'].indexOf((field === null || field === void 0 ? void 0 : field.entity) || ((_c = field === null || field === void 0 ? void 0 : field.elemType) === null || _c === void 0 ? void 0 : _c.entity))) {
        return !!~left.indexOf('.name') ? showStaffDeptName : showStaffDeptCode;
    }
    //开关 停启用
    if ((field === null || field === void 0 ? void 0 : field.name) === 'active') {
        return showStaffActiveOptions;
    }
    return getEntityList(arr, type);
}
export function getEntityList(arr, type) {
    var moneyField = [];
    var dimensionField = [];
    if (type === 'number') {
        moneyField = arr.filter(function (line) { return getType(line) === 'money'; });
    }
    if (type === 'text') {
        dimensionField = arr.filter(function (line) {
            var entity = getDataEntity(line);
            return (entity === null || entity === void 0 ? void 0 : entity.startsWith('basedata.Dimension')) || (entity === null || entity === void 0 ? void 0 : entity.startsWith('organization.Department'));
        });
    }
    var list = arr
        .filter(function (line) {
        var lineType = getType(line);
        if (['text', 'autoNumber'].includes(type)) {
            return ['text', 'autoNumber'].includes(lineType);
        }
        else {
            return getType(line) === type;
        }
    })
        .concat(moneyField)
        .concat(dimensionField);
    //特殊处理: 金额字段、计划字段和档案字段  展示二级联查
    list.forEach(function (item) {
        var type = getType(item);
        var entity = getDataEntity(item);
        if (item.children &&
            type !== 'money' &&
            !item.controlType &&
            !entity.startsWith('basedata.Dimension') &&
            !entity.startsWith('organization.Department')) {
            delete item.children;
        }
    });
    return list;
}
export function getDataEntityIds(data) {
    if (data === void 0) { data = []; }
    return data
        .filter(function (item) { return getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity'); })
        .map(function (oo) { return (oo.id = getDataEntity(oo).split('.')[2]); });
}
export function getType(item) {
    return get(item, 'dataType.type') || get(item, 'type');
}
export function getDataEntity(item) {
    return get(item, 'dataType.entity', '') || get(item, 'entity', '');
}
export function isDataLnikField(item) {
    return getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity');
}
export function isEnumField(item) {
    return getType(item) === 'ref' && getDataEntity(item).startsWith('basedata.Enum');
}
export function getCascaderOptions(entityInfoList) {
    var entityArr = entityInfoList.filter(function (oo) { return getType(oo) !== 'dateRange'; });
    entityArr.forEach(function (vv) {
        var _a;
        if (isDataLnikField(vv)) {
            vv.children = ((_a = vv.children) === null || _a === void 0 ? void 0 : _a.filter(function (oo) { return getType(oo) !== 'dateRange'; })) || [];
        }
    });
    return entityArr;
}
// 通过type:boolean支持枚举选项
export function getBooleanTargetArrByEntity(entityInfoList, name) {
    var targetField = entityInfoList.find(function (el) { return el.name === name; }) || {};
    return targetField.options || [];
}
