/**************************************************
 * Created by nanyuantingfeng on 05/07/2017 15:10.
 **************************************************/
import key from './key'
import { Fetch, Resource } from '@ekuaibao/fetch'
import { QuerySelect } from 'ekbc-query-builder'
import { formatSpecification } from './util/formatter'

const specificationVersions = new Resource('/api/form/v1/specificationVersions')
const specifications2 = new Resource('/api/form/v2/specifications')
const specificationGroups = new Resource('/api/form/v2/specificationGroups')
const property = new Resource('/api/flow/v2/propertySet')
const printTemplate = new Resource('/api/v1/print/template')
const flowPlan = new Resource('/api/v1/flow/planConfigs')
const dimensions = new Resource('/api/v1/basedata/dimensions')
const expenseLink = new Resource('/api/form/v2/requisition/expenseLink')
const enums = new Resource('/api/v1/basedata/enums')
const dataLink = new Resource('/api/v2/datalink/entity')
const fieldMap = new Resource('/api/v1/mapping/fieldMapping')
const feeTypeImport = new Resource('/api/flow/v2/feeTypeImportRule')
const dependence = new Resource('/api/v1/organization/roles/dependence')
const formulaGrammar = new Resource('/api/v1/rule/calculation/check')
const datalinkPermissionField = new Resource('/api/v2/datalink/permission/fields/byEntityId')
const datalinkPermissionFields = new Resource('/api/v2/datalink/permission/fields/byEntityIds')
const permission = new Resource('/api/v2/datalink/permission')
const organization = new Resource('/api/authority/v2/organization')
const organizationAction = new Resource('/api/authority/v2/action')
const powerCode = new Resource('/api/charge/powers/corporation')
const init = new Resource('/api/v1/initialization/template')
const dataLinkV1 = new Resource('/api/v1/datalink')
const bankIdName = new Resource('/api/pay/v1/banks')
const customizeQueryRule = new Resource('/api/v2/datalink/customizeQueryRule')
const staffRangeRule = new Resource('/api/form/v2/condition')
const customizeCloseRule = new Resource('/api/v1/requisition/customcloserule')
const customizeCodeRule = new Resource('/api/v2/basedata/code')
const generateFee = new Resource('/api/v1/requisition/autoAssociate/config')
const upgradeToMicroNewTravel = new Resource("/api/tpp/v2/travelManagement/getTravelManagementConfig")
const propertySet = new Resource('/api/flow/v2/propertySet')
const flowLinkConfig = new Resource('/api/flow/v1/flowLinkConfig')
export function upgradeToMicroservice() {
  return upgradeToMicroNewTravel.GET('', { type: "travelUpgrade" })
}

export function getSpecificationVersions(data) {
  const params = { type: 'expense', ...data }
  return {
    type: key.GET_SPECIFICATION_VERSIONS,
    payload: specificationVersions.GET('/actives', params)
  }
}

export function getMetaSpecification(params) {
  return {
    type: key.GET_META_SPECIFICATION,
    payload: specifications2.GET('/default/$type', { ...params }),
    params
  }
}

export function getSpecificationsByType(params) {
  const { orgId = '', type = '' } = params
  const query = new QuerySelect().filterBy(`(type == "${type}")`).desc('updateTime').asc('name').value()
  return {
    type: key.GET_SPECIFICATIONS_BY_TYPE,
    payload: specifications2.POST('/search', query, { orgId }),
    specificationsType: type
  }
}

export function saveGlobalField(data) {
  return {
    type: key.SAVE_FIELD,
    payload: property.POST('/property', data)
  }
}

export function getPrintTemplates(data) {
  return {
    type: key.GET_PRINT_TEMPLATE_LIST,
    payload: printTemplate.GET('', data),
    billType: data.billType
  }
}

export function getFlowPlanList(params) {
  return {
    type: key.GET_FLOW_LIST,
    payload: flowPlan.GET('/actives', params)
  }
}

export function saveSpecification(data, isCreate, orgId) {
  const params = formatSpecification(data)
  return {
    type: key.SAVE_SPECIFICATION,
    payload: isCreate
      ? specifications2.POST('', params, { orgId })
      : specifications2.PUT('/$id/$enable', params, { orgId })
  }
}

export function delSpecification(params) {
  return {
    type: key.DEL_SPECIFICATION,
    payload: specifications2.DELETE('/$id', params)
  }
}

export function getDimensions(orgId, done) {
  return {
    type: key.GET_DIMENSIONS,
    payload: dimensions.GET('', { orgId }),
    done
  }
}

export function getExpenseLink(data, done) {
  return {
    type: key.GET_EXPENSE_LINK,
    payload: expenseLink.GET('/$id', data),
    done
  }
}

export function getEntityList(params) {
  const join = {
    join: `platformId,platformId,/v2/datalink/platform`
  }
  //修改获取所有业务对象请求
  const action = {
    type: key.GET_DATALINK_ENTITY,
    // payload: dataLink.GET('/getEntityList', { ...join })
    payload: dataLink.POST('/search', {
      select: "id,name,grayver,active,version,createTime,updateTime,nameSpell,corporationId,sourceCorporationId,dataCorporationId,ledgerIds,plannedIds,parentId,code,scoped,multiCurrencyFlag,importOverrideStrategy,visibility,type,disableStrategy,maxUsageCount,writtenOffField,fields,defaultMappingRuleId,filterId,details,homePageVisibleIds,homeVisibility,exportVisibility,ownerDepts,showOnPage,importMethod,allowRecordLog,logFields,showBook,allowAddSubType,dataFilter,platformId(id,name,active,createTime,updateTime,nameSpell,corporationId,dataCorporationId,sourceCorporationId,importMethod,adminIds,type,groupType,properties,desc,source)",
      limit: {
        start: 0,
        count: 1000
      }
    })
  }

  if (params && params.ignoreActive) {
    return {
      ...action,
      includeAll: true
    }
  }

  return action
}

export function getEntityValueById(id) {
  const join = {
    join: 'platformId,platformId,/v2/datalink/platform'
  }
  const params = { ...join, id }
  return {
    type: key.GET_ENTITY_VALUE_BY_ID,
    payload: dataLink.GET('/$id', params)
  }
}

export function getDefaultMappingRuleList(id) {
  return {
    type: key.GET_DEFAULT_MAPRULE_LIST,
    payload: fieldMap.GET('/getListByEntityId/$id', { id })
  }
}

//获取可用模板
const specificationJoin = () => ({
  join: `specifications.components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping`,
  join$1: `specifications.components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform`
})

export function getSpecificationGroupsWithSpecificationVersioned() {
  const params = {
    ...specificationJoin()
  }
  return {
    type: key.GET_SPECIFICATION_GROUPS,
    payload: specificationGroups.GET('/withSpecificationVersioned', params)
  }
}

export function getRecentUsedSpecification() {
  return {
    type: key.GET_RECENT_UESD_SPECIFICATION,
    payload: specificationGroups.GET('/recentlyUsedSpecification')
  }
}

export function getSpecificationGroups(orgId = '') {
  return {
    type: key.GET_SPECIFICATION_GROUPS_All,
    payload: specificationGroups.GET('', { orgId })
  }
}

export function getSpecificationGroupsByIds(ids) {
  return {
    type: key.GET_SPECIFICATION_GROUPS_IDS,
    payload: specificationGroups.GET('/$[ids]', ids)
  }
}

export function setSpecificationGroupName(params) {
  const { data, orgId } = params
  return {
    type: key.SET_SPECIFICATION_GROUP_NAME,
    payload: specificationGroups.POST('', data, { orgId })
  }
}

export function setSpecificationGroup(params) {
  const { data, orgId } = params
  return {
    type: key.SET_SPECIFICATION_GROUP,
    payload: specificationGroups.PUT('/$id', data, { orgId })
  }
}

export function setSpecificationGroupSort(params) {
  return {
    type: key.SET_SPECIFICATION_GROUP_SORT,
    payload: specificationGroups.PUT('/$id/sort', params)
  }
}

export function delSpecificationGroup(params) {
  return {
    type: key.DEL_SPECIFICATION_GROUP_ID,
    payload: specificationGroups.DELETE('/$id', params)
  }
}

export function getParagramCenterGroups() {
  return {
    type: key.GET_PARAGRAMCENTER_SPECIFICATION,
    payload: init.GET('/paragramCenter/Specifications')
  }
}

export function setParagramCenterGroups(params) {
  return {
    type: key.SET_PARAGRAM,
    payload: init.POST('/$templateId', params)
  }
}

export function getFeetypeImportRule(params) {
  return {
    type: key.GET_FEETYPE_IMPORT_RULE,
    payload: feeTypeImport.GET('/baseList/$formType', params)
  }
}

// 获取当前企业能使用的导入方式
export function getFeetypeImportSource(params) {
  const { formType } = params
  const join = {
    formType,
    join:
      'dataLinkEntity.platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments'
  }
  return {
    type: key.GET_FEETYPE_IMPORT_SOURCE,
    payload: feeTypeImport.GET('/sourceMenu', join)
  }
}

// 获取当前企业能使用的导入方式
export function getFeetypeImportListById(params) {
  const { id, formType } = params
  const data = {
    formType,
    join: `id,organizationData,/authority/v2/action/share/show/$SPECIFICATION`
  }
  return {
    type: key.GET_FEETYPE_IMPORT_LIST_BY_ID,
    payload: feeTypeImport.GET(`/menuRule/${id}`, { ...data })
  }
}

export function getFeeTypeImportRuleEntry(params) {
  return feeTypeImport.GET(`/$sourceId/$linkId`, { ...params })
}

export function setFeetypeImportList(data) {
  const { result, orgId } = data
  return {
    type: key.SET_FEETYPE_IMPORT_LIST,
    payload: feeTypeImport.POST('', result, { orgId })
  }
}

export function getFeetypeImportRuleById(params) {
  const { id, formType } = params
  const join = {
    deviceType: 'DESKTOP',
    formType: formType,
    join:
      'dataLinkEntity.platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments',
    join$1: 'linkDataLinkEntity.platformId,platformId,/v2/datalink/platform'
  }
  return {
    type: key.GET_FEETYPE_IMPORT_RULE_BY_ID,
    payload: feeTypeImport.GET(`/menuList/${encodeURIComponent(id)}`, join)
  }
}
const joinReferenceData = () =>
  'components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments'
const joinAssignmentRule = () => 'components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping'

export function getFeetypeImportByDataLinkEntityId(type, dataLinkEntityId, billData, linkDataLinkEntityId) {
  return {
    type: key.GET_FEETYPE_IMPORT_BY_DATA_LINK_ENTITY_ID,
    payload: feeTypeImport.POST(
      `/available/feeTypes`,
      { type, dataLinkEntityId, billData, linkDataLinkEntityId },
      {
        join: `expenseSpecificationId,expenseSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`,
        join$1: `requisitionSpecificationId,requisitionSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`,
        join$2: `receiptSpecificationId,receiptSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`
      }
    )
  }
}

export function getFeetypeImport(type, dataLinkEntityId, billData, linkDataLinkEntityId) {
  const joinReferenceData = () =>
    'components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments'
  const joinAssignmentRule = () => 'components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping'

  return {
    type: key.GET_FEETYPE_IMPORT_ALL,
    payload: feeTypeImport.POST(
      '/available/linkedFeeTypes',
      {
        type,
        dataLinkEntityId,
        linkDataLinkEntityId
      },
      {
        join: `expenseSpecificationId,expenseSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`,
        join$1: `requisitionSpecificationId,requisitionSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`,
        join$2: `receiptSpecificationId,receiptSpecification,/form/v1/specificationVersions?join=${joinReferenceData()}&join=${joinAssignmentRule()}`
      }
    )
  }
}

function getLinkageListWithPageSize(data, url) {
  let items = []
  let pages = 1
  const pageSize = 300
  const params = {
    start: 0,
    count: pageSize,
    ...data
  }
  const getLinkageList = () => {
    return dependence.GET(url ? url : '', params).then((resp) => {
      items = items.concat(resp.items)
      if (resp.count !== items.length) {
        params.start = pages * pageSize
        pages++
        return getLinkageList()
      }
      return { items, count: resp.count }
    })
  }
  return getLinkageList()
}
export function getLinkageList(obj, url) {
  return {
    type: key.GET_LINKAGE_LIST,
    payload: getLinkageListWithPageSize(obj, url)
  }
}
export function setLinkageList(list = []) {
  return {
    type: key.SET_LINKAGE_LIST,
    payload: list
  }
}

export function checkFormulaGrammar(param) {
  return {
    type: key.CHECK_FORMULA_GRAMMAR,
    payload: formulaGrammar.POST('', param)
  }
}

export function getDatalinkPermissionFieldsById(id) {
  return {
    type: key.GET_DATALINK_PERMISSIN_FIELDS_BYID,
    payload: datalinkPermissionField.GET('/$id', { id })
  }
}

export function getDatalinkPermissionFieldsByIds(id) {
  return {
    type: key.GET_DATALINK_PERMISSIN_FIELDS_BYIDS,
    payload: datalinkPermissionFields.GET('/[id]', { id })
  }
}
export function getDatalinkEntityFieldsByIds(dataLinkId) {
  return {
    type: key.GET_DATALINK_ENTITYFIELDS_BYIDS,
    payload: dataLinkV1.GET('/entityField/$dataLinkId', { dataLinkId })
  }
}

export function saveDatalinkPermission(params) {
  return {
    type: key.SAVE_DATALINK_PERMISSION,
    payload: permission.POST('', params)
  }
}

export function saveDatalinkFilterId(params) {
  return {
    type: key.SAVE_DATALINK_FILTERID,
    payload: params.id ? permission.POST('', params) : permission.POST('/saveFilterId', params)
  }
}

export function getDatalinkPermission(id) {
  return {
    type: key.GET_DATALINK_PERMISSION,
    payload: permission.GET('/$id', { id })
  }
}

export function getDatalinkPermissionCopy(ids) {
  return {
    type: key.GET_DATALINK_PERMISS_COPY,
    payload: permission.POST(`/copy`, ids)
  }
}
export function getSpecificationsByIds(params) {
  return {
    type: key.GET_SPECIFICATIONS_BY_IDS,
    payload: specifications2.GET(`/[ids]`, params)
  }
}

export function getAllSpecifications(params) {
  return {
    type: key.GET_ALL_SPECIFICATIONS,
    payload: specifications2.GET(`/actives`, params)
  }
}

export function getOrganizationList(params) {
  return organization.GET('/list/$model', params)
}

export function getCancelShare(params) {
  return organizationAction.GET('/share/cancel/$orgId/$relationId/$type/$action', { ...params })
}

export function postShareOrganization(params) {
  return {
    type: key.POST_SHARE_ORGANIZATION,
    payload: organizationAction.POST('/share/organization', params)
  }
}

// 转交模版或者审批流
export function transferOrganization(params) {
  return organizationAction.POST('/shift/organization/$orgId/type/$type', params)
}

// 不分组织权限，全部获取单据模版，仅含{id,name,active}值
export function getAllSpecificationsByTypes(typeList) {
  let filterStr = ''
  typeList.forEach((type, idx) => {
    filterStr += `${idx === 0 ? '' : '||'}(type == "${type}")`
  })
  const query = new QuerySelect().filterBy(filterStr).desc('updateTime').asc('name').value()
  return specifications2.POST('/search/all', query)
}

// 查询企业是否开通某个charge权限
export function checkPowerCode(params) {
  return powerCode.GET('', params)
}

// 查询不是该组织的自定义档案字段
export function getDimensionFilterByOrg(orgId = '') {
  return {
    type: key.GET_DIMENSION_FILTER_BY_ORG,
    payload: organizationAction.GET('/dimensions/noRelations', { orgId })
  }
}

export function getExpenseLinkVisible(expenseLinkVisible) {
  return {
    type: key.GET_EXPENSELINK_VISIBLE,
    expenseLinkVisible
  }
}

export function getSubmitterSettingRule() {
  return {
    type: key.GET_SUBMITTER_SETTING_RULE,
    payload: fieldMap.GET('/type/staff')
  }
}
// 启用单据模板
export function putEnableSpecification(params) {
  const { id, orgId } = params
  return specifications2.PUT('/restore/$id', { id }, { orgId })
}

// 获取银行id和name
export function getBankIdAndName() {
  return {
    type: key.PUT_BANK_ID_NAME,
    payload: bankIdName.PUT('/bankIdAndName')
  }
}

//获取字段自定义联查规则
export function getCustomizeQueryRule(field) {
  return {
    type: key.GET_CUSTOMIZE_QUERY_RELE,
    payload: customizeQueryRule.GET(field.isApportion ? '/listByField/$field?isApportion=true' : '/listByField/$field', { field: field.field })
  }
}
//新建，编辑字段自定义联查规则
export function setCustomizeQueryRule(params) {
  return {
    type: key.SET_CUSTOMIZE_QUERY_RELE,
    payload: customizeQueryRule.PUT('/saveOrUpdate', params)
  }
}
//获取字段人员可选范围规则
export function getStaffRangeRule(field) {
  return {
    type: key.GET_STAFF_RANGE_RULE,
    payload: staffRangeRule.GET('/', {
      start: 0,
      count: 999
    })
  }
}
//新建字段人员可选范围规则
export function setStaffRangeRule(params) {
  return {
    type: key.SET_STAFF_RANGE_RULE,
    payload: staffRangeRule.POST('/', params)
  }
}
//编辑字段人员可选范围规则
export function editStaffRangeRule(params) {
  return {
    type: key.EDIT_STAFF_RANGE_RULE,
    payload: staffRangeRule.PUT('/$id', params)
  }
}
//申请单自定义关闭规则
export function setCustomizeCloseRule(params) {
  return {
    type: key.SET_CUSTOMIZE_CLOSE_RULE,
    payload: customizeCloseRule.POST('/', params)
  }
}
//获取申请单自定义关闭规则
export function getCustomizeCloseRule() {
  return {
    type: key.GET_CUSTOMIZE_CLOSE_RULE,
    payload: customizeCloseRule.GET('/')
  }
}
//通过id获取申请单自定义关闭规则，（为了获取已删除的规则）
export function getCustomizeCloseRuleById(id) {
  return {
    type: key.GET_CUSTOMIZE_CLOSE_RULE_BY_ID,
    payload: customizeCloseRule.GET(`/${id}`)
  }
}

//删除申请单自定义关闭规则
export function deleteCustomizeCloseRule(id) {
  return {
    type: key.DELETE_CUSTOMIZE_CLOSE_RULE,
    payload: customizeCloseRule.DELETE(`/${id}`)
  }
}
//获取自定义编码规则列表
export function getCodeRuleList(params) {
  return {
    type: key.GET_CODE_RULE_LIST,
    payload: customizeCodeRule.GET('/list', params)
  }
}

export function getGenerateFeeConfig() {
  return generateFee.POST('/list', {
    limit: {
      start: 0,
      count: 999
    }
  })
}

export function setGenerateFeeConfig(params) {
  return generateFee.POST('/add', params)
}

export function updateGenerateFeeConfig(params) {
  return generateFee.PUT('/update', params)
}
export function deleteGenerateFeeConfig(params) {
  return generateFee.DELETE('/del/$id', params)
}

// 获取部门赋值规则
export function getDepartmentSettingRule() {
  return {
    type: key.GET_DEPARTMENT_SETTING_RULE,
    payload: fieldMap.GET('/type/department')
  }
}

//根据type（DEPARTMENT, STAFF）获取基础数据
export function getPropertySetByType(params) {
  return propertySet.GET('/$type', params)
}

//获取部门基础数据
export function getPropertySetByDepartment(){
  return getPropertySetByType({ type: 'DEPARTMENT' })
}

// 单据查询字段配置
// 新增配置
export function addFlowLinkConfig(params){
  return flowLinkConfig.POST("", params)
}

// 修改配置
export function setFlowLinkConfig(params){
  return flowLinkConfig.PUT("/$id", params)
}

// 获取配置
export function getFlowLinkConfig(params){
  return flowLinkConfig.GET("/$id", params)
}

// 删除配置
export function delFlowLinkConfig(params){
  return flowLinkConfig.DELETE("/$id", params)
}

// 配置列表
export function getFlowLinkConfigList(params){
  return flowLinkConfig.POST("/list", params)
}


export default {
  saveSpecification,
  delSpecification,
  getSpecificationVersions,
  getDimensions,
  saveGlobalField,
  getPrintTemplates,
  getFlowPlanList: getFlowPlanList,
  getSpecificationsByType,
  getExpenseLink,
  getEntityList,
  getSpecificationGroups,
  getSpecificationGroupsByIds,
  setSpecificationGroupName,
  setSpecificationGroup,
  setSpecificationGroupSort,
  delSpecificationGroup,
  getFeetypeImportRule,
  getFeetypeImportSource,
  getFeetypeImportListById,
  getFeetypeImportRuleById,
  setFeetypeImportList,
  getFeetypeImportByDataLinkEntityId,
  getSpecificationsByIds,
  getAllSpecifications,
  getOrganizationList,
  getCancelShare,
  postShareOrganization,
  transferOrganization,
  checkPowerCode,
  getDimensionFilterByOrg,
  getAllSpecificationsByTypes,
  getParagramCenterGroups,
  getSubmitterSettingRule,
  putEnableSpecification,
  getBankIdAndName,
  getCustomizeQueryRule,
  getCodeRuleList,
  getDepartmentSettingRule,
  getPropertySetByDepartment,
  addFlowLinkConfig,
  setFlowLinkConfig,
  getFlowLinkConfig,
  delFlowLinkConfig,
  getFlowLinkConfigList,
  getPropertySetByType
}
