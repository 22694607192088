export const ID = '@custom-specification'

export default {
  ID: ID,
  GET_SPECIFICATION_VERSIONS: `${ID}/GET_SPECIFICATION_VERSIONS`,
  GET_RECENT_UESD_SPECIFICATION: `${ID}/GET_RECENT_UESD_SPECIFICATION`,
  GET_FIELDS: `${ID}/GET_FIELDS`,
  SAVE_FIELD: `${ID}/SAVE_FIELD`,
  GET_PRINT_TEMPLATE_LIST: `${ID}/GET_PRINT_TEMPLATE_LIST`,
  GET_FLOW_LIST: `${ID}/GET_FLOW_LIST`,
  SET_PARAGRAM: `${ID}/SET_PARAGRAM`,

  GET_META_SPECIFICATION: `${ID}/GET_META_SPECIFICATION`,
  SAVE_SPECIFICATION: `${ID}/SAVE_SPECIFICATION`,
  DEL_SPECIFICATION: `${ID}/DEL_SPECIFICATION`,
  GET_DIMENSIONS: `${ID}/GET_DIMENSIONS`,

  GET_SPECIFICATIONS_BY_TYPE: `${ID}/ GET_SPECIFICATIONS_BY_TYPE`,

  GET_EXPENSE_LINK: `${ID}/ GET_EXPENSE_LINK`,
  GET_ENUMS: `${ID}/GET_ENUMS`,
  GET_DATALINK_ENTITY: `${ID}/GET_DATALINK_ENTITY`,
  GET_ENTITY_VALUE_BY_ID: `${ID}/GET_ENTITY_VALUE_BY_ID`,
  GET_DEFAULT_MAPRULE_LIST: `${ID}/GET_DEFAULT_MAPRULE_LIST`,

  //单据分组
  GET_SPECIFICATION_GROUPS_All: `${ID}/GET_SPECIFICATION_GROUPS_All`,
  GET_SPECIFICATION_GROUPS: `${ID}/GET_SPECIFICATION_GROUPS`,
  GET_SPECIFICATION_GROUPS_IDS: `${ID}/GET_SPECIFICATION_GROUPS_IDS`,
  SET_SPECIFICATION_GROUP_NAME: `${ID}/SET_SPECIFICATION_GROUP_NAME`,
  SET_SPECIFICATION_GROUP: `${ID}/SET_SPECIFICATION_GROUP`,
  SET_SPECIFICATION_GROUP_SORT: `${ID}/SET_SPECIFICATION_GROUP_SORT`,
  DEL_SPECIFICATION_GROUP_ID: `${ID}/DEL_SPECIFICATION_GROUP_ID`,
  GET_PARAGRAMCENTER_SPECIFICATION: `${ID}/GET_PARAGRAMCENTER_SPECIFICATION`,

  GET_FEETYPE_IMPORT_RULE: `${ID}/GET_FEETYPE_IMPORT_RULE`,
  GET_FEETYPE_IMPORT_SOURCE: `${ID}/GET_FEETYPE_IMPORT_SOURCE`,
  GET_FEETYPE_IMPORT_RULE_BY_ID: `${ID}/GET_FEETYPE_IMPORT_RULE_BY_ID`,
  GET_FEETYPE_IMPORT_LIST_BY_ID: `${ID}/GET_FEETYPE_IMPORT_LIST_BY_ID`,
  SET_FEETYPE_IMPORT_LIST: `${ID}/SET_FEETYPE_IMPORT_LIST`,
  GET_FEETYPE_IMPORT_BY_DATA_LINK_ENTITY_ID: `${ID}/GET_FEETYPE_IMPORT_BY_DATA_LINK_ENTITY_ID`,
  GET_LINKAGE_LIST: `${ID}/GET_LINKAGE_LIST`,
  CHECK_FORMULA_GRAMMAR: `${ID}/CHECK_FORMULA_GRAMMAR`,
  SET_LINKAGE_LIST: `${ID}/SET_LINKAGE_LIST`,
  GET_DATALINK_PERMISSIN_FIELDS_BYID: `${ID}/GET_DATALINK_PERMISSIN_FIELDS_BYID`,
  SAVE_DATALINK_PERMISSION: `${ID}/SAVE_DATALINK_PERMISSION`,
  SAVE_DATALINK_FILTERID: `${ID}/SAVE_DATALINK_FILTERID`,
  GET_DATALINK_PERMISSION: `${ID}/GET_DATALINK_PERMISSION`,
  GET_DATALINK_PERMISSIN_FIELDS_BYIDS: `${ID}/GET_DATALINK_PERMISSIN_FIELDS_BYIDS`,
  GET_DATALINK_ENTITYFIELDS_BYIDS: `${ID}/GET_DATALINK_ENTITYFIELDS_BYIDS`,
  GET_DATALINK_PERMISS_COPY: `${ID}/GET_DATALINK_PERMISS_COPY`,
  GET_SPECIFICATIONS_BY_IDS: `${ID}/GET_SPECIFICATIONS_BY_IDS`,
  GET_ALL_SPECIFICATIONS: `${ID}/GET_ALL_SPECIFICATIONS`,
  POST_SHARE_ORGANIZATION: `${ID}/POST_SHARE_ORGANIZATION`,
  POST_TRANSFER_ORGANIZATION: `${ID}/POST_TRANSFER_ORGANIZATION`,
  GET_DIMENSION_FILTER_BY_ORG: `${ID}/GET_DIMENSION_FILTER_BY_ORG`,
  GET_EXPENSELINK_VISIBLE: `${ID}/GET_EXPENSELINK_VISIBLE`,
  GET_FEETTYPE_IMPORT_RULE_ENTRY: `${ID}/GET_FEETTYPE_IMPORT_RULE_ENTRY`,
  GET_FEETYPE_IMPORT_ALL: `${ID}/GET_FEETYPE_IMPORT_ALL`,
  GET_SUBMITTER_SETTING_RULE: `${ID}/GET_SUBMITTER_SETTING_RULE`,
  PUT_BANK_ID_NAME: `${ID}/PUT_BANK_ID_NAME`,
  GET_CUSTOMIZE_QUERY_RELE: `${ID}/GET_CUSTOMIZE_QUERY_RELE`,
  SET_CUSTOMIZE_QUERY_RELE: `${ID}/SET_CUSTOMIZE_QUERY_RELE`,
  GET_CODE_RULE_LIST: `${ID}/GET_CODE_RULE_LIST`,
  GET_STAFF_RANGE_RULE:`${ID}/GET_STAFF_RANGE_RULE`,
  SET_STAFF_RANGE_RULE:`${ID}/SET_STAFF_RANGE_RULE`,
  EDIT_STAFF_RANGE_RULE:`${ID}/EDIT_STAFF_RANGE_RULE`,
  SET_CUSTOMIZE_CLOSE_RULE:`${ID}/SET_CUSTOMIZE_CLOSE_RULE`,
  GET_CUSTOMIZE_CLOSE_RULE_BY_ID:`${ID}/GET_CUSTOMIZE_CLOSE_RULE_BY_ID`,
  GET_CUSTOMIZE_CLOSE_RULE:`${ID}/GET_CUSTOMIZE_CLOSE_RULE`,
  DELETE_CUSTOMIZE_CLOSE_RULE:`${ID}/DELETE_CUSTOMIZE_CLOSE_RULE`,
  GET_DEPARTMENT_SETTING_RULE: `${ID}/GET_DEPARTMENT_SETTING_RULE`,
  GET_DEPARTMENT_PROPERTY_SET: `GET_DEPARTMENT_PROPERTY_SET`,
}
