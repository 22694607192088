/**************************************************
 * Created by nanyuantingfeng on 07/07/2017 11:33.
 **************************************************/

const strXXX = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

export default function uuid() {
  let d = new Date().getTime()
  return strXXX.replace(/[xy]/g, c => {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
