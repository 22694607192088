/**
 * Created by xingbozhang on 17/7/14.
 */

import uuid from './uuid'
import { formatDefaultValue } from './formatter'
import { get } from 'lodash'
const defaultPriority = [
  {
    type: 'MANUALLY_FILL_IN',
    value: 5
  },
  {
    type: 'LINKAGE_ASSIGNMENT',
    value: 10
  },
  {
    type: 'AUTOMATIC_CALCULATION',
    value: 15
  },
  {
    type: 'COST_STANDARD',
    value: 20
  }
]
function fnGetType(field) {
  if (field.name === 'vphoto_order') {
    return 'custom'
  }
  if (field.name === 'splitCalculation') {
    return 'splitCalculation'
  }
  if ((get(field, 'dataType.elemType.fields') || []).find((item) => item.name === 'dataLinkId')) {
    return 'dataLinkEdits'
  }
  if (get(field, 'dataType.elemType.entity') && get(field, 'dataType.elemType.entity').startsWith('engine.Connect')) {
    return 'engineConnect'
  }
  if (field.dataType.type == 'ref') {
    if (field.name === 'payeeId' || field.name === 'feeDetailPayeeId') {
      return 'payeeInfo'
    }

    if (field.dataType && field.dataType.entity === 'basedata.city') {
      return 'city'
    }
    if (field?.dataType?.entity?.startsWith('connect.BlockUI')) {
      return 'engineBlockUI'
    }
    if (get(field, 'dataType.entity', '').indexOf('datalink.DataLinkEntity') >= 0) {
      return 'dataLink'
    }

    return 'select'
  }
  if (field?.dataType?.elemType?.type === 'travel') {
    return 'travel'
  }
  if (field.name === 'flowLinks') {
    return 'flowLinks'
  }
  return field.dataType.type
}

const basic = (field) => {
  return {
    id: uuid(),
    field: field.name,
    type: fnGetType(field),
    label: field.label,
    cnLabel: field.cnLabel,
    enLabel: field.enLabel
  }
}

const text = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    editable: true,
    optional: false,
    defaultValue: {
      type: 'none'
    },
    priority: defaultPriority,
    maxLength: 14
  }
}
/**
 * @description 供应商组件扩展
 * @param {*} field
 * @returns
 */
const supplier = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    editable: true,
    optional: true,
    defaultValue: {
      type: 'none'
    }
  }
}

/**
 * @description 单据查询组件
 * @param {*} field
 * @returns
 */
 const flowLinks = (field) => {
  return {
    ...basic(field),
    // placeholder: `${i18n.get("请选择")}${field.label}`,
    editable: true,
    optional: true,
    multiple: true,
    defaultValue: {
      type: 'none'
    }
  }
}
/**
 * @description 补助组件扩展
 * @param {*} field
 * @returns
 */
const subsidy = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${field.label}`,
    editable: true,
    optional: true,
    defaultValue: {
      type: 'none'
    },
    hide: true,
    hideVisibility: {
      fullVisible: false,
      staffs: [],
      roles: [],
      departments: [],
      departmentsIncludeChildren: true
    }
  }
}
const engineConnect = (field) => {
  const entityStr = get(field, 'dataType.elemType.entity', '')
  const count = entityStr.lastIndexOf('.')
  const id = entityStr.substring(count + 1, entityStr.length)
  return {
    ...basic(field),
    placeholder: '',
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: false,
    dataType: field.dataType,
    referenceData: id,
    importMode: 'SINGLE',
    limitEntityIds: null
  }
}
const blockUI = (field) => {
  const entityStr = get(field, 'dataType.entity', '')
  const count = entityStr.lastIndexOf('.')
  const id = entityStr.substring(count + 1, entityStr.length)
  let config = {
    ...basic(field),
    placeholder: '',
    defaultValue: {
      type: 'none'
    },
    editable: false,
    optional: true,
    isReview: true,
    dataType: field.dataType,
    referenceData: id
  }
  if (id === 'LOAN_STATISTICS') {
    //借款统计
    config = { ...config, blockUIConfig: { loanStatistics: 'fromBorrower' }, isReview: false }
  } else if (entityStr.indexOf('connect.BlockUI.widgetCard') >= 0) {
    //自定义互联卡片
    config = { ...config, blockUIConfig: { loanStatisticsWidgetCard: 'show' }, isReview: false }
  } else if (id !== 'PEER_AUDIT_MAIN') {
    //PEER_AUDIT_MAIN(同行人稽查) 不走blockUIConfig
    //目前有 APPROVAL_STATISTICS(审批统计) ENTERPRISE_PURCHASE_ORDER(合思企业购) ADJUSTMENTNODE(KA预算调整)，后期增加系统预置的blockUI类型的全局字段会走这个逻辑
    config = { ...config, blockUIConfig: { [id]: 'hide' }, isReview: false }
  }
  return config
}
const number = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    max: 10000000000,
    min: __min(field.name),
    editable: true,
    optional: false,
    dataType: field.dataType, //TODO -- 有没有更好的做法？？？
    defaultValue: { type: 'constant', value: 0 },
    priority: defaultPriority
  }
}

const amount = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    isShowThousandsSeparator: false,
    max: '10000000000.00',
    min: '0.01',
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: false,
    priority: defaultPriority
  }
}

const date = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    withTime: field.name == 'repaymentDate',
    defaultValue: {
      type: 'predefine',
      value: field.name == 'repaymentDate' ? 'repayment.date' : 'submit.date'
    },
    editable: true,
    optional: false,
    priority: defaultPriority
  }
}

const dateRange = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    withTime: false,
    defaultValue: {
      type: 'predefine',
      value: 'submit.date'
    },
    editable: true,
    optional: false,
    priority: defaultPriority
  }
}

const attachment = (field) => {
  return {
    id: uuid(),
    field: field.name,
    type: 'attachments',
    label: field.label,
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: true
  }
}

const apportion = (field) => {
  return {
    id: uuid(),
    dataType: field.dataType,
    field: field.name,
    type: 'apportions',
    label: field.label,
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: true,
    placeholder: '',
    showInDetails: false,
    specificationIds: []
  }
}

const select = (field) => {
  const { dataType = {} } = field
  let defaultValue
  if (dataType.entity === 'organization.Department') {
    defaultValue = {
      defaultValue: {
        type: 'predefine',
        value: 'submit.department'
      },
      selectRange: 'all'
    }
  } else if (dataType.entity.startsWith('basedata.Dimension')) {
    defaultValue = {
      defaultValue: {
        type: 'none'
      },
      selectRange: 'all'
    }
  } else if (dataType.entity === 'pay.PayeeInfo') {
    defaultValue = {
      defaultValue: {
        type: 'predefine',
        value: 'entity.default'
      }
    }
  } else {
    defaultValue = {
      defaultValue: {
        type: 'none'
      }
    }
  }
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请选择')}${field.label}`,
    //'dataType': field.dataType,
    ...defaultValue,
    editable: true,
    optional: false
  }
}

const annotate = () => ({
  id: uuid(),
  type: 'annotation',
  label: i18n.get('说明文本'),
  url: '',
  maxLength: 2000,
  onlyEditVisible: true
})

const separator = () => {
  return {
    id: uuid(),
    type: 'separator',
    label: ''
  }
}

const group = () => ({
  id: uuid(),
  type: 'group',
  label: i18n.get('分组标题'),
  maxLength: 40
})

const switcher = (field) => {
  return {
    id: uuid(),
    field: field.name,
    defaultValue: {
      type: 'constant',
      value: field.defaultActive || true
    },
    placeholder: field.label,
    type: 'switcher',
    label: field.label,
    editable: true,
    priority: defaultPriority
  }
}

const custom = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    editable: true,
    optional: false,
    defaultValue: {
      type: 'none'
    }
  }
}

const splitDetail = (field) => {
  return {
    id: uuid(),
    ...basic(field),
    placeholder: `${i18n.get('请输入')}${field.label}`,
    editable: true,
    optional: true,
    defaultValue: {
      type: 'none'
    }
  }
}

const invoice = (field) => {
  return {
    id: uuid(),
    label: i18n.get('发票'),
    type: 'invoice',
    showInDetails: false,
    field: 'invoiceForm',
    placeholder: null,
    editable: false,
    optional: true,
    defaultValue: { type: 'predefine', value: 'noWrite' },
    invoiceType: {
      wait: true,
      exist: true,
      unify: { limit: false, choose: false, invoiceCorporation: [] },
      noExist: true,
      isRequired: false
    },
    importMode: { query: true, upload: true, scan: true, photo: true, ocr: true }
  }
}

const listComponent = (field, componentType) => {
  const elemType = field.dataType.elemType
  const entity = get(field, 'dataType.elemType.entity')
  switch (elemType.type) {
    case 'attachment':
      return attachment(field)
    case 'ref':
      return get(field, 'name') === 'travelers'
        ? travelers(field)
        : entity.startsWith('basedata.Dimension')
        ? multipleDimension(field)
        : multipleStaff(field)
    case 'complex':
      if (field.name === 'details') {
        return details(field, componentType)
      }
      if (field.name === 'apportions') {
        return apportion(field)
      }
      if (field.name === 'trips') {
        return trips(field, componentType)
      }
  }
}

const details = (field, componentType = 'details') => {
  //明细字段对应两种类型的components：消费记录(details)，申请记录(requisitionDetails)
  return {
    id: uuid(),
    field: field.name,
    type: componentType,
    label: i18n.get('费用明细'),
    editable: true,
    optional: false,
    showType: 'LIST',
    defaultValue: { type: 'none' }
  }
}

const trips = (field, componentType) => {
  return {
    id: uuid(),
    field: field.name,
    type: 'trips',
    label: i18n.get('差旅行程'),
    editable: true,
    optional: false,
    defaultValue: { type: 'none' }
  }
}

const travelers = (field) => {
  return {
    id: uuid(),
    field: field.name,
    type: 'select',
    label: field.label,
    editable: true,
    optional: false,
    multiple: true,
    placeholder: i18n.get(`{__k0}{__k1}，默认提交人出行`, {
      __k0: i18n.get('请选择'),
      __k1: field.label
    }),
    defaultValue: {
      type: 'predefine',
      value: 'submitter'
    }
  }
}

const multipleStaff = (field) => {
  return {
    id: uuid(),
    field: field.name,
    type: 'select',
    label: field.label,
    editable: true,
    optional: false,
    multiple: true,
    placeholder: i18n.get(`{__k0}{__k1}`, { __k0: i18n.get('请选择'), __k1: field.label }),
    defaultValue: { type: 'none' }
  }
}
const multipleDimension = (field) => {
  return {
    id: uuid(),
    field: field.name,
    type: 'select',
    label: field.label,
    editable: true,
    optional: false,
    multiple: true,
    placeholder: i18n.get(`{__k0}{__k1}`, { __k0: i18n.get('请选择'), __k1: field.label }),
    defaultValue: { type: 'none' },
    selectRange: 'all'
  }
}
const enumComponent = (field) => {
  return {
    ...basic(field),
    type: 'Enum',
    placeholder: `${i18n.get('请选择')}${field.label}`,
    optional: false,
    editable: true
  }
}
const dataLink = (field) => {
  const baseField = ['supplierSettlement', 'supplierReconciliation']
  const entityStr = get(field, 'dataType.entity', '')
  const count = entityStr.lastIndexOf('.')
  const id = entityStr.substring(count + 1, entityStr.length)
  return {
    ...basic(field),
    placeholder: `${i18n.get('请选择')}${field.label}`,
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: baseField.includes(field.name) ? true : false,
    isLinkageAssignment: undefined,
    assignmentRule: '',
    referenceData: id,
    dataType: field.dataType
  }
}

const dataLinkEdits = (field) => {
  const entityList = get(field, 'dataType.elemType.fields') || []
  const entity = entityList.find((item) => item.name === 'dataLinkId')
  const entityStr = get(entity, 'dataType.entity', '')
  const count = entityStr.lastIndexOf('.')
  const id = entityStr.substring(count + 1, entityStr.length)
  return {
    ...basic(field),
    placeholder: '',
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: false,
    dataType: field.dataType,
    referenceData: id,
    importMode: 'SINGLE',
    behaviour: 'INSERT',
    travelType: false,
    dataLinkHiddenLabel: false
  }
}

const travelPlanning = (field) => {
  return {
    ...basic(field),
    placeholder: '',
    defaultValue: {
      type: 'none'
    },
    editable: true,
    optional: false,
    dataType: field.dataType,
    travelType: false,
    limitTravelTypeIds: null
  }
}

const tmcOrder = (field) => ({
  ...basic(field),
  placeholder: `${i18n.get('请选择')}${field.label}`,
  defaultValue: {
    type: 'none'
  },
  editable: true,
  optional: false,
  assignmentRule: '',
  isLinkageAssignment: true,
  dataType: field.dataType
})
export function generateComponent(field, componentType) {
  let type = typeof field === 'object' ? field.dataType.type : field
  if (get(field, 'dataType.entity', '').indexOf('datalink.DataLinkEntity') >= 0) {
    type = 'dataLink'
  }
  if ((get(field, 'dataType.elemType.fields') || []).find((item) => item.name === 'dataLinkId')) {
    type = 'dataLinkEdits'
  }
  if (get(field, 'dataType.elemType.entity') && get(field, 'dataType.elemType.entity').startsWith('engine.Connect')) {
    type = 'engineConnect'
  }
  if (get(field, 'dataType.elemType.type') === 'travel') {
    type = 'travel'
  }
  if (field?.dataType?.entity?.startsWith('connect.BlockUI')) {
    return blockUI(field)
  }
  if (field.name === 'vphoto_order') {
    return custom(field)
  }
  // 拆分字段, 类别唯一且不可新建 故而特殊处理
  if (field.name === 'splitCalculation') {
    return splitDetail(field)
  }
  if (field.name === 'flowLinks') {
    return flowLinks(field)
  }
  switch (type) {
    case 'text':
      return text(field)
    case 'number':
      return number(field)
    case 'money':
      return amount(field)
    case 'date':
      return date(field)
    case 'dateRange':
      return dateRange(field)
    case 'list':
      return listComponent(field, componentType)
    case 'ref':
      return select(field)
    case 'annotate':
      return annotate()
    case 'separator':
      return separator()
    case 'group':
      return group()
    case 'Enum':
      return enumComponent(field)
    case 'boolean':
      return switcher(field)
    case 'dataLink':
      return dataLink(field)
    case 'dataLinkEdits':
      return dataLinkEdits(field)
    case 'invoice':
      return invoice(field)
    case 'engineConnect':
      return engineConnect(field)
    case 'subsidy':
      return subsidy(field)
    case 'supplier':
      return supplier(field)
    case 'travel':
      return travelPlanning(field)
    case 'tmcOrder':
      return tmcOrder(field)
  }
}

function changeComponentsByKeys(components, allBaseFields, addFields, removeFields) {
  addFields.forEach((key) => {
    if (key === 'requisitionDetails') key = 'details'
    if (!components.find((v) => v.field === key)) {
      if (key === 'details') {
        components = __addComponent(components, key, allBaseFields, 'requisitionDetails')
      } else {
        components = __addComponent(components, key, allBaseFields)
      }
    }
  })
  removeFields.forEach((key) => {
    if (key === 'requisitionDetails') {
      components = __removeComponent(components, 'details', allBaseFields)
    } else {
      components = __removeComponent(components, key, allBaseFields)
    }
  })

  return components
}

function buildRequisitionComponent(components, config, allBaseFields, tripManageComponents) {
  const rule = typeof config.applyContentRule === 'object' ? config.applyContentRule.rule : config.applyContentRule
  components = addOrRemoveComponent(
    components,
    config,
    allBaseFields,
    config.linkRequisitionInfo,
    'linkRequisitionInfo'
  )
  if (rule === 'travel') {
    components = components.filter((v) => v.type !== 'separator')
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['travelPlanning'],
      ['requisitionDetails', 'requisitionMoney']
    )
  }
  if (rule === 'amountAndTravel') {
    components = components.filter((v) => v.type !== 'separator')
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['travelPlanning', 'requisitionMoney'],
      ['requisitionDetails', 'separator']
    )
  }

  if (rule === 'auto') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['requisitionDetails'],
      ['travelers', 'trips', 'requisitionMoney', 'travelPlanning']
    )
  }
  if (rule === 'manual') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['requisitionMoney'],
      ['travelers', 'trips', 'requisitionDetails', 'travelPlanning']
    )
  }

  if (rule === 'trip') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['travelers', 'trips'],
      ['requisitionDetails', 'requisitionMoney']
    )
  }

  if (rule === 'amountAndTrip') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['travelers', 'trips', 'requisitionMoney'],
      ['requisitionDetails']
    )
  }

  if (rule === 'travelManage') {
    if (tripManageComponents && tripManageComponents.length > 0) {
      return addOrRemoveComponent(
        tripManageComponents,
        config,
        allBaseFields,
        config.linkRequisitionInfo,
        'linkRequisitionInfo'
      )
    } else {
      return changeComponentsByKeys(
        components,
        allBaseFields,
        [],
        ['travelers', 'trips', 'requisitionMoney', 'requisitionDetails']
      )
    }
  }

  return components
}
function buildPermitComponent(components, config, allBaseFields) {
  const rule = typeof config.applyContentRule === 'object' ? config.applyContentRule.rule : config.applyContentRule

  if (rule === 'auto') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['requisitionDetails'],
      ['trips', 'requisitionMoney', 'travelPlanning']
    )
  }
  if (rule === 'manual') {
    return changeComponentsByKeys(
      components,
      allBaseFields,
      ['requisitionMoney'],
      ['trips', 'requisitionDetails', 'travelPlanning']
    )
  }

  return components
}

function addOrRemoveComponent(components, config, allBaseFields, isAdd, fieldName) {
  if (isAdd) {
    //增加关联申请component
    let c = components.find((v) => v.field === fieldName)
    if (!c) {
      components = __addComponent(components, fieldName, allBaseFields)
      c = components.find((v) => v.field === fieldName)
    }

    c.optional = !config.mustApply
    return components
  } else {
    //删除关联申请component
    components.forEach((v) => {
      if (v.defaultValue && typeof v.defaultValue === 'object' && v.defaultValue.value === 'submit.requisition') {
        v.defaultValue = formatDefaultValue(v)
      }
    })
    return __removeComponent(components, fieldName)
  }
}

export function rebuildComponent(components, config, allBaseFields, tripManageComponents) {
  components = components.slice()
  if (config.ability === 'loan') {
    if (config.isLimitRepaymentDate) {
      //增加还款日期component
      return __addComponent(components, 'repaymentDate', allBaseFields)
    } else {
      //删除还款日期component
      return __removeComponent(components, 'repaymentDate')
    }
  } else if (config.ability === 'pay') {
    if (config.isRequirePayeeInfo) {
      //增加收款人component
      return __addComponent(components, 'payeeId', allBaseFields)
    } else {
      //删除收款人component
      return __removeComponent(components, 'payeeId')
    }
  } else if (config.ability === 'requisition') {
    return buildRequisitionComponent(components, config, allBaseFields, tripManageComponents)
  } else if (config.ability === 'permit') {
    return buildPermitComponent(components, config, allBaseFields, tripManageComponents)
  } else if (config.ability === 'apply') {
    const expenseLink = components.find((line) => line.field === 'expenseLinks')
    const fieldName = expenseLink ? expenseLink.field : 'expenseLink'
    return addOrRemoveComponent(components, config, allBaseFields, config.canApply, fieldName)
  } else if (config.ability === 'chargeAgainst') {
    if (config.isChargeAgainst) {
      return __removeComponent(components, 'payeeId', allBaseFields)
    } else {
      return __addComponent(components, 'payeeId')
    }
  } else if (config.ability === 'canLoan') {
    return buildComponentInCanLoan(components, config, allBaseFields)
  } else if (config.ability === 'expense') {
    if (config.isInvoiceBagCode) {
      return __addComponent(components, 'invoiceBagCode', allBaseFields)
    } else {
      return __removeComponent(components, 'invoiceBagCode')
    }
  }
}

function __addComponent(components, field, allBaseFields, componentType = '') {
  components = components.slice()
  if (!!components.find((v) => v.field === field)) {
    return components
  }
  if (!allBaseFields || allBaseFields.length <= 0) return components
  const f = allBaseFields.find((item) => {
    return item.name === field
  })
  if (!f) return components
  const newComponent = generateComponent(f, componentType)
  components = components.concat(newComponent)
  return components
}

function __removeComponent(components, field) {
  return components.slice().filter((item) => {
    return item.field !== field
  })
}

function buildComponentInCanLoan(components, config, allBaseFields) {
  if (config.value.checked) {
    components = __addComponent(components, 'loanMoney', allBaseFields)
    const canPay = config.value.vv.find((v) => v === 'pay')
    const canLoan = config.value.vv.find((v) => v === 'loan')
    if (canPay) {
      components = __addComponent(components, 'payeeId', allBaseFields)
    } else {
      components = __removeComponent(components, 'payeeId')
    }
    if (canLoan) {
      components = __addComponent(components, 'repaymentDate', allBaseFields)
    } else {
      components = __removeComponent(components, 'repaymentDate')
    }
  } else {
    components = __removeComponent(components, 'repaymentDate')
    components = __removeComponent(components, 'payeeId')
    components = __removeComponent(components, 'loanMoney')
  }
  return components
}

const __min = (name) => {
  let min = -1000000000
  switch (name) {
    case 'invoice':
      min = '0'
      break
  }
  return min
}
