export default {
  didi: { name: '#EDico-didi', style: { color: '#1E96FA' } },
  officialCard: { name: '#EDico-BOC', style: { color: '#982D30' } },
  excel: { name: '#EDico-Excel', style: { fill: '#3D9364' } },
  invoicePdf: { name: '#EDico-PDF', style: { fill: '#F46D44' } },
  flight: { name: '#EDico-airplane', style: { color: '#26C6DA' } },
  ekbFlight: { name: '#EDico-airplane', style: { color: '#26C6DA' } },
  train: { name: '#EDico-train', style: { color: '#B0BEC5' } },
  hotel: { name: '#EDico-wineshop', style: { color: '#42A5F5' } },
  invoiceScan: { name: '#EDico-scan', style: { color: '#52C230' } },
  invoiceManual: { name: '#EDico-edit', style: { color: '#47ADE7' } },
  invoiceWeChat: { name: '#EDico-WeChat', style: { color: '#1EA114' } },
  OCR: { name: '#EDico-photo-ai', style: { color: '#FA962A' } },
  invoiceOCR: { name: '#EDico-photo-ai', style: { color: '#FA962A' } },
  medicalInvoiceOCR: { name: '#EDico-yiliaofapiao', style: { color: '#00C874' } },
  record: { name: '#EDico-record4', style: { color: '#47ADE7' } },
  aifapiao: { name: '#EDico-aifapiao', style: { color: '0b5fff' } },
  invoiceAlipay: { name: '#EDico-payzfb1', style: { color: '#0b5fff' } }
}
