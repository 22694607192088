/**************************************************
 * Created by nanyuantingfeng on 24/06/2017 16:10.
 **************************************************/
// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import loadable from '@loadable/component'

export const Universal_Unique_Key = `customSpecification.pc`

import {
  getDimensions,
  getRecentUsedSpecification,
  getSpecificationGroups,
  getSpecificationGroupsWithSpecificationVersioned,
  getSpecificationsByType,
  getEntityList,
  getEntityValueById,
  getDefaultMappingRuleList,
  getFeetypeImportRule,
  getFeetypeImportSource,
  getFeetypeImportRuleById,
  getFeetypeImportListById,
  setFeetypeImportList,
  getFeetypeImportByDataLinkEntityId,
  setSpecificationGroupName,
  getDatalinkPermissionFieldsById,
  saveDatalinkPermission,
  saveDatalinkFilterId,
  getDatalinkPermission,
  getDatalinkPermissionFieldsByIds,
  getDatalinkEntityFieldsByIds,
  getDatalinkPermissionCopy,
  getSpecificationsByIds,
  getAllSpecifications,
  getOrganizationList,
  getFlowPlanList,
  getCancelShare,
  postShareOrganization,
  transferOrganization,
  checkPowerCode,
  getDimensionFilterByOrg,
  setParagramCenterGroups,
  getAllSpecificationsByTypes,
  getFeeTypeImportRuleEntry,
  getFeetypeImport,
  getSubmitterSettingRule,
  getCustomizeQueryRule,
  getStaffRangeRule,
  setStaffRangeRule,
  editStaffRangeRule,
  setCustomizeCloseRule,
  deleteCustomizeCloseRule,
  getCustomizeCloseRule,
  getCustomizeCloseRuleById,
  setCustomizeQueryRule,
  getGenerateFeeConfig,
  deleteGenerateFeeConfig,
  getConditionList,
  upgradeToMicroservice,
  getDepartmentSettingRule,
  addFlowLinkConfig,
  setFlowLinkConfig,
  getFlowLinkConfig,
  delFlowLinkConfig,
  getFlowLinkConfigList,
  getPropertySetByDepartment
} from './specification.action'
import FeeTypeImportIconMap from './detail/feeTypeImportIconMap'
import { showInDetailsBlacklist } from './util/showInDetailsBlacklist'

export default [
  {
    id: '@custom-specification',
    reducer: () => import('./specification.reducer'),
    path: '/custom-specification',
    ref: '/',
    onload: () => import('./specification.view'),
    'set:feetypeImportList'(data) {
      return api.dispatch(setFeetypeImportList(data))
    },
    'set:paragram'(data) {
      return api.dispatch(setParagramCenterGroups(data))
    },
    'get:feetypeImportList:byId'(...ags) {
      return api.dispatch(getFeetypeImportListById(...ags))
    },
    'get:feetypeImportEntry:byId'(...ags) {
      return getFeeTypeImportRuleEntry(...ags)
    },
    'get:dimensions'(orgId) {
      return api.dispatch(getDimensions(orgId))
    },
    'get:feetypeImportRule'(params) {
      return api.dispatch(getFeetypeImportRule(params))
    },
    'get:feetypeImportSource'(...ags) {
      return api.dispatch(getFeetypeImportSource(...ags))
    },
    'get:feetypeImportRule:byId'(...ags) {
      return api.dispatch(getFeetypeImportRuleById(...ags))
    },
    'get:feetypeImport:ByDataLinkEntityId'(type, dataLinkEntityId, billData, linkDataLinkEntityId) {
      return api.dispatch(getFeetypeImportByDataLinkEntityId(type, dataLinkEntityId, billData, linkDataLinkEntityId))
    },
    'get:feetypeImport:ByDataLinkEntityId'(type, specificationId, dataLinkEntityId, billData) {
      return api.dispatch(getFeetypeImportByDataLinkEntityId(type, specificationId, dataLinkEntityId, billData))
    },
    'get:specificationGroups'() {
      return api.dispatch(getSpecificationGroups())
    },
    'get:specificationGroups:withSpecificationVersioned'() {
      return api.dispatch(getSpecificationGroupsWithSpecificationVersioned())
    },
    'get:customizeQueryRule'(field) {
      return api.dispatch(getCustomizeQueryRule(field))
    },
    'set:customizeQueryRule'(params) {
      return api.dispatch(setCustomizeQueryRule(params))
    },
    'get:staffRangeRule'(field) {
      return api.dispatch(getStaffRangeRule(field))
    },
    'set:staffRangeRule'(params) {
      return api.dispatch(setStaffRangeRule(params))
    },
    'get:customizeCloseRuleModal'() {
      return api.dispatch(getCustomizeCloseRule())
    },
    'get:customizeCloseRuleById'(id) {
      return api.dispatch(getCustomizeCloseRuleById(id))
    },
    'set:customizeCloseRuleModal'(params) {
      return api.dispatch(setCustomizeCloseRule(params))
    },
    'delete:customizeCloseRule'(params) {
      return api.dispatch(deleteCustomizeCloseRule(params))
    },
    'edit:staffRangeRule'(params) {
      return api.dispatch(editStaffRangeRule(params))
    },
    'get:recent:used:specification'() {
      return api.dispatch(getRecentUsedSpecification())
    },
    'get:specificationsByType'(params) {
      return api.dispatch(getSpecificationsByType(params))
    },
    'get:datalink:entity'(params) {
      return api.dispatch(getEntityList(params))
    },
    'get:datalink:entity:value:byId'(params) {
      return api.dispatch(getEntityValueById(params))
    },
    'get:default:rule:list'(params) {
      return api.dispatch(getDefaultMappingRuleList(params))
    },
    'set:pecification:groupame'(params) {
      return api.dispatch(setSpecificationGroupName(params))
    },
    'get:datalink:permission:fields:byId'(params) {
      return api.dispatch(getDatalinkPermissionFieldsById(params))
    },
    'get:datalink:permission:fields:byIds'(params) {
      return api.dispatch(getDatalinkPermissionFieldsByIds(params))
    },
    'get:datalink:entityField:byIds'(params) {
      return api.dispatch(getDatalinkEntityFieldsByIds(params))
    },
    'get:datalink:permission:copy'(ids) {
      return api.dispatch(getDatalinkPermissionCopy(ids))
    },
    'save:datalink:permission'(params) {
      return api.dispatch(saveDatalinkPermission(params))
    },
    'save:datalink:filterId'(params) {
      return api.dispatch(saveDatalinkFilterId(params))
    },
    'get:datalink:permission'(params) {
      return api.dispatch(getDatalinkPermission(params))
    },
    'import:FeeTypeImportIconMap': () => FeeTypeImportIconMap,
    'import:showInDetailsBlacklist': () => showInDetailsBlacklist,
    'get:specification:components'(ids) {
      return api.dispatch(getSpecificationsByIds(ids))
    },
    'get:all:specification'(params) {
      return api.dispatch(getAllSpecifications(params))
    },
    'get:organization:list'(params) {
      return getOrganizationList(params)
    },
    'get:flow:list'(params) {
      return api.dispatch(getFlowPlanList(params))
    },
    'get:cancel:share'(params) {
      return getCancelShare(params)
    },
    'post:share:organization'(params) {
      return api.dispatch(postShareOrganization(params))
    },
    'transfer:organization'(params) {
      return transferOrganization(params)
    },
    'get:all:specifications:by:types'(typeList) {
      return getAllSpecificationsByTypes(typeList)
    },
    'check:power:code'(params) {
      return checkPowerCode(params)
    },
    'get:dimension:filter:by:org'(params) {
      return api.dispatch(getDimensionFilterByOrg(params))
    },
    'get:feetype:import:available'(type, dataLinkEntityId, billData, linkDataLinkEntityId) {
      return api.dispatch(getFeetypeImport(type, dataLinkEntityId, billData, linkDataLinkEntityId))
    },
    'get:submitter:rule:list'() {
      return api.dispatch(getSubmitterSettingRule())
    },
    getGenerateFeeConfig() {
      return getGenerateFeeConfig()
    },
    deleteGenerateFeeConfig(params) {
      return deleteGenerateFeeConfig(params)
    },
    'get:upgradeToMicroservice'() {
      return upgradeToMicroservice()
    },
    'get:department:rule:list'() {
      return api.dispatch(getDepartmentSettingRule())
    },
    'get:department:propertySet'(){
      return getPropertySetByDepartment()
    },
    'get:flow:link:config:list': getFlowLinkConfigList,
    'del:flow:link:config': delFlowLinkConfig,
    
  },
  {
    point: '@@components',
    namespace: '@custom-specification',
    onload: () => [
      { key: 'FieldSetting', component: () => import('./parts/right-part/FieldSetting') },
      { key: 'Simulator', component: () => import('./parts/right-part/field-setting/Simulator') }
    ]
  },
  {
    point: '@@layers',
    prefix: '@custom-specification',
    onload: () => import('./layer')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'custom-specification',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 5,
        //divider: true,
        label: '单据模板',
        href: '/custom-specification',
        icon: 'enterprise-custom-template'
      }
    ]
  },
  {
    id: '@customSpecificationId',
    path: '/custom-specification/:id',
    ref: '/',
    onload: () => import('./specification.view'),
  },
  {
    resource: '@custom-specification',
    value: {
      ['util/generate-component']: require('./util/generate-component'),
      ['CustomSpecification/DataLinkConditional']: loadable(() =>
        import('./parts/right-part/modal/DataLinkConditional')
      ),
      ['DataLinkConditional/Utils']: require('./parts/right-part/modal/Utils'),
      ['RulesSelect']:require('./elements/flowLinksOptions/RulesSelect'),
      ['DataLinkAssignment']:loadable(() =>
      import('./parts/right-part/field-components/datalinkAssignment/datalinkAssignment')
      ),
      ['CustomizeCloseRule']:loadable(() =>
      import('./parts/right-part/modal/CustomizeCloseRule/customizeCloseRule')
      ),
    }
  }
]
