/***************************************************
 * Created by nanyuantingfeng on 2020/4/20 17:36. *
 ***************************************************/

export const showInDetailsBlacklist = [
  'feeDatePeriod',
  'city',
  'fromCity',
  'toCity',
  'feeDate',
  'amount',
  'invoiceForm',
  'feeDetailPayeeId'
]

export default showInDetailsBlacklist
