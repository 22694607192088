/**************************************************
 * Created by kaili on 2017/7/27 下午8:42.
 **************************************************/
import { getV } from '@ekuaibao/lib/lib/help'

const select = (list) => {
  return list.map((o) => o.id)
}

function formatDataByChargeAgainst(configs) {
  const chargeAgainst = configs.find((v) => v.ability === 'chargeAgainst')
  const value = chargeAgainst.isChargeAgainst
  if (value) {
    return configs.filter((a) => a.ability !== 'pay' && a.ability !== 'writtenOff')
  }
  return configs
}

export function formatSpecification(specification) {
  const { isEnable } = specification
  const isCache = specification.isCache
  const { configs, name, visibility, description } = specification
  let components = getV(specification, 'components', [])
  components = components.map((o) => {
    o.dataType && delete o.dataType
    return { ...o, id: undefined }
  })
  if (visibility && !visibility.fullVisible) {
    const { roles, departs, staffs } = visibility
    specification.visibility.staffs = staffs
    specification.visibility.departs = departs
    specification.visibility.roles = roles
  }
  // configs = formatDataByChargeAgainst(configs)
  let result = {
    id: isCache ? undefined : specification.id,
    name,
    specificationGroupId: specification.specificationGroupId,
    color: specification.color,
    icon: specification.icon,
    visibility,
    type: specification.type,
    state: specification.state,
    configs,
    components,
    rules: specification.rules,
    pri: specification.pri || 0,
    mustUpdateTemplate: specification.mustUpdateTemplate,
    description,
    budgetPermitAllowEdit: specification?.budgetPermitAllowEdit || false
  }
  if (!isCache) {
    result = !!isEnable ? { ...result, enable: 'true' } : { ...result, enable: 'false' }
  }
  return result
}

export function formatDefaultValue(component = {}) {
  let { field, dataType = {} } = component

  if (dataType.entity === 'organization.Department') {
    return {
      type: 'predefine',
      value: 'submit.department'
    }
  }

  if (dataType.type === 'date' || dataType.type === 'dateRange') {
    return {
      type: 'predefine',
      value: 'submit.date'
    }
  }

  if (!field) return undefined

  return {
    type: 'none'
  }
}
